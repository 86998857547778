import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {environment} from 'src/environments/environment';

import {NavigationService} from './services/navigation.service';
import {AuthFacade} from './store/auth-state/+state/auth.facade';

import {filter, Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {NavigationEnd, Router} from '@angular/router';
import {ManagerFacade} from './store/manager/+state/manager.facade';
import {FullscreenService} from './services/fullscreen.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	static history: string[] = [];
	public isLoggedIn$ = this.authFacade.isLoggedIn$;
	public userData$ = this.authFacade.userData$;
	intervalSubs: Subscription;
	intervalSubsStatistics: Subscription;
	constructor(
		private authFacade: AuthFacade,
		private managerFacade: ManagerFacade,
		private navService: NavigationService,
		private toaster: ToastrService,
		public translateService: TranslateService,
		private router: Router,
		public fullscreenService: FullscreenService
	) {
		console.log('Monitoring UI version -', environment.version);
		const favIcon: any =
			document.querySelector("link[rel*='icon']") ||
			document.createElement('link');
		favIcon.type = 'image/x-icon';
		favIcon.rel = 'shortcut icon';
		favIcon.href = '/assets/logo_white.svg';
		document.getElementsByTagName('head')[0].appendChild(favIcon);
		this.translateService.setDefaultLang('de');
		this.translateService.use('de');

		this.isLoggedIn$.subscribe(v => {
			console.log('isLoggedIn', v);
		});
		this.listenErrors();
		this.listenNotifications();
	}
	ngOnInit(): void {
		this.authFacade.checkToken();

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				AppComponent.history.push((event as NavigationEnd).urlAfterRedirects);
			});
		document.addEventListener('fullscreenchange', this.fullscreenChangeHandler);
		document.addEventListener(
			'mozfullscreenchange',
			this.fullscreenChangeHandler
		);
		document.addEventListener(
			'webkitfullscreenchange',
			this.fullscreenChangeHandler
		);
		document.addEventListener(
			'msfullscreenchange',
			this.fullscreenChangeHandler
		);
	}
	fullscreenChangeHandler = () => {
		// Logic to handle the change
		if (this.fullscreenService.isFullscreen()) {
			console.log('Entered fullscreen mode');
		} else {
			console.log('Exited fullscreen mode');
		}
	};
	getDeviceStatiscs() {}
	logout() {
		this.authFacade.logout();

		this.navService.login();
	}
	private listenNotifications() {
		this.managerFacade.messageUpdate$.subscribe(v => {
			if (v) {
				this.toaster.success(v, '', {
					timeOut: 5000
				});
			}
		});
	}
	private listenErrors() {
		this.authFacade.error$.subscribe((error: any) => {
			console.log(error);
			if (error) {
				this.toaster.error(
					error.url +
						' ' +
						'STATUS:' +
						error.status +
						' ' +
						'MESSAGE:' +
						error?.error?.message,
					'Backend error',
					{
						timeOut: 5000
					}
				);
			}
		});
	}
	toggleFullscreen(): void {
		if (this.fullscreenService.isFullscreen()) {
			this.fullscreenService.exitFullscreen();
		} else {
			this.fullscreenService.enterFullscreen();
		}
	}
}

import {createReducer, on, Action} from '@ngrx/store';
import * as managerActions from './manager.actions';
import Screensaver from 'src/app/models/Screensaver';
import {Venue} from 'src/app/models/Venue';
import {OptionGroup} from 'src/app/models/OptionGroup';
import {Table} from 'src/app/models/Table';
import ArticleCategory from 'src/app/models/ArticleCategory';
import {HttpErrorResponse} from '@angular/common/http';
import Order from 'src/app/models/Order';
import {OrderStatus} from 'src/app/models/OrderStatus';
import Article from 'src/app/models/Article';

export const MANAGER_FEATURE_KEY = 'manager';

export interface ManagerState {
	loaded: boolean;
	error: null | HttpErrorResponse;
	screensavers: Screensaver[];
	venue: Venue | null;
	optionGroups: OptionGroup[];
	tables: Table[];
	categories: ArticleCategory[];
	orders: Order[];
	orderStatusFiler: OrderStatus | null;
	filters: {
		category: string;
		type: string;
		status: string;
		search: string;
	};
	visibleArticles: Article[];
	openManagerPage: boolean;
	messageUpdate: string;
	errorSyncWolt: string;
	errorSyncUber: string;
	errorSyncJet: string;
	successSync: boolean;
	statusUber: {isActive: boolean};
	statusWolt: {isActive: boolean};
	salesOverview: any;
}

export const initialState: ManagerState = {
	loaded: true,
	error: null,
	venue: null,
	optionGroups: [],
	tables: [],
	categories: [],
	screensavers: [],
	orders: [],
	orderStatusFiler: null,
	filters: {
		category: '',
		type: '',
		status: '',
		search: ''
	},
	visibleArticles: [],
	openManagerPage: false,
	messageUpdate: '',
	errorSyncWolt: '',
	errorSyncUber: '',
	errorSyncJet: '',
	successSync: false,
	statusUber: {isActive: false},
	statusWolt: {isActive: false},
	salesOverview: []
};

const managerReducer = createReducer(
	initialState,

	on(managerActions.loadVenue, (state, _) => ({
		...state,
		venue: null
	})),
	on(managerActions.responseSuccessVenue, (state, {response}) => ({
		...state,
		venue: response,
		openManagerPage: true
	})),
	on(managerActions.responseErrorVenue, (state, {error}) => ({
		...state,
		error: error,
		openManagerPage: false
	})),

	on(managerActions.loadCategories, (state, _) => ({
		...state
	})),
	on(managerActions.responseSuccessCategories, (state, {response}) => ({
		...state,
		categories: response
	})),
	on(managerActions.responseErrorVenue, (state, {error}) => ({
		...state,
		categories: [],
		error: error
	})),

	on(managerActions.loadTables, (state, _) => ({
		...state,
		tables: []
	})),
	on(managerActions.responseSuccessTables, (state, {response}) => ({
		...state,
		tables: response.map(table => {
			if (!table?.status) {
				table = {
					...table,
					status: 'OFFLINE'
				};
			}
			return table;
		})
	})),
	on(managerActions.responseErrorTables, (state, {error}) => ({
		...state,
		tables: [],
		error: error
	})),

	on(managerActions.loadScreensavers, (state, _) => ({
		...state,
		screensavers: []
	})),
	on(managerActions.responseSuccessScreensavers, (state, {response}) => ({
		...state,
		screensavers: response
	})),
	on(managerActions.responseErrorScreensavers, (state, {error}) => ({
		...state,
		screensavers: [],
		error: error
	})),

	on(managerActions.loadOptionGroups, (state, _) => ({
		...state
	})),
	on(managerActions.responseSuccessOptionGroups, (state, {response}) => ({
		...state,
		optionGroups: response
	})),
	on(managerActions.responseErrorOptionGroups, (state, {error}) => ({
		...state,
		optionGroups: [],
		error: error
	})),

	//load first time Order before Socket will start listening job

	on(managerActions.loadOrders, (state, _) => ({
		...state
	})),
	on(managerActions.responseSuccessOrders, (state, {response}) => ({
		...state,
		orders: response
	})),
	on(managerActions.responseErrorOrders, (state, {error}) => ({
		...state,
		orders: [],
		error: error
	})),

	//update Order
	on(managerActions.updateOrder, (state, {order}) => ({
		...state,
		orders: state.orders.find(item => order._id == item._id)
			? state.orders.map(item => {
					if (order._id == item._id) {
						return order;
					}
					return item;
			  })
			: state.orders.concat(order)
	})),
	on(managerActions.filterOrderStatusAdd, (state, {status}) => ({
		...state,
		orderStatusFiler: status
	})),
	on(managerActions.updateTable, (state, {table}) => ({
		...state,
		tables: state.tables.map(item => {
			if (table._id == item._id) {
				return table;
			}
			return item;
		})
	})),
	on(managerActions.setStockManagerFilters, (state, {filters}) => ({
		...state,
		filters: filters
	})),
	on(managerActions.updateOpeningHoursActionSuccess, (state, {response}) => ({
		...state,
		venue: state.venue
			? {
					...state.venue,
					openingHours: response
			  }
			: null
	})),
	on(managerActions.updateArticlesActiveSuccess, (state, response) => ({
		...state,
		messageUpdate: response.respone
	})),
	on(managerActions.syncJetError, (state, response) => ({
		...state,
		errorSyncJet: response.error.error.name + ' ' + response.error.error.message
	})),
	on(
		managerActions.syncWoltError,
		(state, response) => (
			console.log(response),
			{
				...state,
				errorSyncWolt:
					response.error.error.name + ' ' + response.error.error.message
			}
		)
	),
	on(managerActions.syncUberError, (state, response) => ({
		...state,
		errorSyncUber:
			response.error.error.name + ' ' + response.error.error.message
	})),

	on(managerActions.syncJetSuccess, (state, response) => ({
		...state,
		successSync: true
	})),
	on(
		managerActions.syncJetSuccess,
		(state, response) => (
			console.log(response),
			{
				...state,
				successSync: true
			}
		)
	),
	on(managerActions.syncWoltSuccess, (state, response) => ({
		...state,
		successSync: true
	})),
	on(managerActions.killSuccess, (state, _) => ({
		...state,
		successSync: false
	})),
	on(managerActions.statusUberSuccess, (state, response) => ({
		...state,
		statusUber: response
	})),
	on(managerActions.statusWoltSuccess, (state, response) => ({
		...state,
		statusWolt: response
	})),
	on(managerActions.salesOverviewSuccess, (state, response) => ({
		...state,
		salesOverview: response.response
	})),
	on(managerActions.resetSalesOverview, state => ({
		...state,
		salesOverview: []
	}))
);

export function reducer(state: ManagerState | undefined, action: Action) {
	return managerReducer(state, action);
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {User} from '../models/User';
import {Observable} from 'rxjs';
import {Table} from '../models/Table';
import {Venue} from '../models/Venue';

import {environment} from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	constructor(private http: HttpClient) {}

	BASE_URL = environment.apiUrl;
	AUTH_URL = '/v1/auth';
	GENERAL_URL = '/v1/general';
	MONITORING_URL = '/v1/monitoring';

	login(data: {email: String; password: String}): Observable<User> {
		return this.http.post<User>(`${this.BASE_URL}${this.AUTH_URL}/login`, data);
	}

	refresh(refreshToken: string) {
		return this.http.post<User>(`${this.BASE_URL}${this.AUTH_URL}/refresh`, {
			refreshToken
		});
	}
	// get venue by customer group  ->
	getTablesVenue(id: string): Observable<Table[]> {
		return this.http.get<Table[]>(
			`${this.BASE_URL}${this.GENERAL_URL}/venue/${id}/tables`
		);
	}
	getVenue(id: string) {
		return this.http.get<Venue>(
			`${this.BASE_URL}${this.GENERAL_URL}/venue/${id}`
		);
	}
	activateDeactivateActivateUber(venueId: string, value: boolean) {
		return this.http.patch(
			`${this.BASE_URL}${this.GENERAL_URL}/venue/${venueId}/external-order-providers/uber-eats`,
			{
				status: value
			},
			{observe: 'response', responseType: 'text'}
		);
	}
	activateDeactivateActivateWolt(venueId: string, value: boolean) {
		return this.http.patch(
			`${this.BASE_URL}${this.GENERAL_URL}/venue/${venueId}/external-order-providers/wolt`,
			{
				status: value
			},
			{observe: 'response', responseType: 'text'}
		);
	}
	updateVenueDelivery(venue: Venue) {
		return this.http.patch<Venue>(
			`${this.BASE_URL}${this.GENERAL_URL}/venue/${venue._id}`,
			{
				...venue
			}
		);
	}

	changeOrderOffsetWolt(
		venueId: string,
		value: {
			deliveryTime: number;
			preparationTime: number;
		}
	) {
		return this.http.patch(
			`${this.BASE_URL}${this.GENERAL_URL}/venue/${venueId}/external-order-providers/wolt/offsets`,
			value
		);
	}
	changeOrderOffsetUber(
		venueId: string,
		value: {
			preparationTime: number;
		}
	) {
		return this.http.patch(
			`${this.BASE_URL}${this.GENERAL_URL}/venue/${venueId}/external-order-providers/uber-eats/offsets`,
			value
		);
	}
}

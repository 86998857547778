<nav class="horizontal-menu">
	<div class="show-block">
		<a mat-raised-button [routerLink]="'/' + urlLinkEnum.stockManager()">
			<mat-icon>bar_chart</mat-icon>
			{{ 'admin_console.stock_management' | translate }}
		</a>

		<a
			class="last-btn"
			mat-raised-button
			[routerLink]="'/' + urlLinkEnum.salesOverview()"
		>
			<mat-icon>bar_chart</mat-icon>
			{{ 'stock_management.title' | translate }}
		</a>
	</div>
	<!-- <a mat-raised-button>
    <mat-icon>settings</mat-icon>
    {{ 'admin_console.machine' | translate }}
  </a>-->
	<div class="show-block">
		<a mat-raised-button [routerLink]="'/' + urlLinkEnum.timeSettings()">
			<mat-icon>settings</mat-icon>
			{{ 'admin_console.time_settings' | translate }}
		</a>

		<a mat-raised-button (click)="logout()" class="out-color last-btn">
			<mat-icon>logout</mat-icon>
			{{ 'common.logout' | translate }}
		</a>
	</div>
	<!-- <div class="show-block">
    <a
      mat-raised-button
      class="button-delivery"
      (click)="openDialogSetTime(deliveryProvider.WOLT)">
      <div>
        <img src="../assets/wolt.svg" class="logo" height="18" />
        <h3> {{ 'admin_console.smoothr_delivery' | translate }}
        </h3>
      </div>
    </a>

    <a
      class="last-btn button-delivery"
      mat-raised-button
      (click)="openDialogSetTime(deliveryProvider.UBER)">
      <div>
        <img src="../assets/uber_eats.png" class="logo" height="18" />
        <h3> {{ 'admin_console.smoothr_delivery' | translate }}
        </h3>
      </div>
    </a>
  </div> -->
	<!-- <div class="show-block">
    <a
      mat-raised-button
      class="button-delivery"
      (click)="updateSmoothrOffsetsType()">
      <div>
        <img src="../../../assets/tresen/smoothr_logo.svg" alt="logo" class="logo">
        <h3>
          {{ 'admin_console.smoothr_delivery' | translate }}
        </h3>
      </div>
    </a>
  </div> -->
	<!-- <a mat-raised-button [routerLink]="'/' + urlLinkEnum.openingHours()">
    <mat-icon>access_time</mat-icon>
    {{ 'admin_console.opening_hours' | translate }}
  </a> -->
	<div class="show-block">
		<a mat-raised-button class="button-delivery" (click)="updateUber()">
			<div>
				<img src="../assets/uber_eats.png" class="logo" height="15" />
				@if (isVenueOpen$ | async) {
					@if (isUberEatsActiveInVenue$ | async; as valueWolt) {
						<h3 class="green">{{ 'common.online' | translate }}</h3>
					} @else {
						<h3 class="red">{{ 'common.offline' | translate }}</h3>
					}
				} @else {
					<h3 class="red">{{ 'common.closed' | translate }}</h3>
				}
			</div>
		</a>
		@if (venueValue$ | async; as venue) {
			<a
				mat-raised-button
				class="button-delivery last-btn"
				(click)="updateSmoothrWithParams(venue, preorderTypeEnum.DELIVERY)"
			>
				<div>
					<h3 class="title-text">
						{{ 'admin_console.self_delivery' | translate }}
					</h3>
					@if (isVenueOpen$ | async) {
						@if (venue.deliveryEnabled) {
							<h3 class="green">{{ 'common.online' | translate }}</h3>
						} @else {
							<h3 class="red">{{ 'common.offline' | translate }}</h3>
						}
					} @else {
						<h3 class="red">{{ 'common.closed' | translate }}</h3>
					}
				</div>
			</a>
		}
	</div>

	@if (venueValue$ | async; as venue) {
		<div class="show-block">
			<a
				mat-raised-button
				class="button-delivery last-btn"
				(click)="updateWolt()"
			>
				<div>
					<img src="../assets/wolt.svg" class="logo" height="18" />
					@if (isVenueOpen$ | async) {
						@if (isWoltActiveInVenue$ | async; as valueWolt) {
							<h3 class="green">{{ 'common.online' | translate }}</h3>
						} @else {
							<h3 class="red">{{ 'common.offline' | translate }}</h3>
						}
					} @else {
						<h3 class="red">{{ 'common.closed' | translate }}</h3>
					}
				</div>
			</a>

			<a
				mat-raised-button
				class="button-delivery last-btn"
				(click)="updateSmoothrWithParams(venue, preorderTypeEnum.TAKE_AWAY)"
			>
				<div>
					<h3 class="title-text">
						{{ 'admin_console.self_take_away' | translate }}
					</h3>
					@if (isVenueOpen$ | async) {
						@if (venue.preorderTakeAway) {
							<h3 class="green">{{ 'common.online' | translate }}</h3>
						} @else {
							<h3 class="red">{{ 'common.offline' | translate }}</h3>
						}
					} @else {
						<h3 class="red">{{ 'common.closed' | translate }}</h3>
					}
				</div>
			</a>
		</div>
	}
	<div class="show-block">
		<a
			mat-raised-button
			class="button-delivery"
			href="https://restaurants.takeaway.com"
			target="_blank"
		>
			<div>
				<img src="../assets/lieferando.png" class="logo" height="15" />
				<h3 class="text_small">
					{{ 'admin_console.delivery_info' | translate }}
				</h3>
			</div>
		</a>
		<a mat-raised-button class="not-visible last-btn"> </a>
	</div>
</nav>

<app-manager-header
	[venue]=" venue$  | async"
	[showBack]="true"
></app-manager-header>

<div class="lang-block">
	<div
		[class.selected]="languageEnum.German === translate.currentLang"
		(click)="changeLang(languageEnum.German)"
		class="lang-item"
		expand="block"
	>
		<img src="../../../assets/lang/de.svg" />

		<h3>{{ 'DE' | translate }}</h3>
	</div>
	<div
		[class.selected]="languageEnum.GreatBritain === translate.currentLang"
		(click)="changeLang(languageEnum.GreatBritain)"
		class="lang-item"
		expand="block"
	>
		<img src="../../../assets/lang/gb.svg" />

		<h3>{{ 'EN' | translate }}</h3>
	</div>
</div>
<div class="main">
	<div class="questions">
		@for (question of questions; track $index) {
		<div
			class="question-item"
			[class.selected]="selectedQuestion === question"
			(click)="selectQuestion(question)"
		>
			<p class="question">
				<span class="number">{{question}}.</span>{{"faq.questions." + question +
				".question" | translate}}
			</p>
		</div>
		}
	</div>
	<div class="answers">
		<h2 class="title">
			{{"faq.questions." + selectedQuestion + ".question" | translate}}
		</h2>
		<p
			class="answer"
			[innerHTML]="('faq.questions.' + selectedQuestion + '.answer') | translate"
		></p>
	</div>
</div>

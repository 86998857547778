import {inject, Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import * as managerActions from './manager.actions';
import {ManagerService} from './manager.service';
import {map, switchMap, catchError, mergeMap, delay} from 'rxjs/operators';
import {forkJoin, of, timer} from 'rxjs';
import {NavigationService} from 'src/app/services/navigation.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class ManagerEffects {
	private navService = inject(NavigationService);
	private toastrServ = inject(ToastrService);

	loadVenue$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.loadVenue),
			switchMap(value => {
				return this.managerService.getVenueById(value.venueId).pipe(
					map(response => {
						return managerActions.responseSuccessVenue({
							response: response
						});
					}),
					catchError(error => {
						return of(managerActions.responseErrorVenue({error}));
					})
				);
			})
		)
	);

	loadScreensaver$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.loadScreensavers),
			switchMap(value => {
				return this.managerService.getScreenSaverByVenueId(value.venueId).pipe(
					map(response => {
						return managerActions.responseSuccessScreensavers({
							response: response
						});
					}),
					catchError(error => {
						return of(managerActions.responseErrorScreensavers({error}));
					})
				);
			})
		)
	);

	loadCategories$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.loadCategories),
			switchMap(value => {
				console.log('LOAD CATEGORIES');
				return this.managerService
					.getArticleCategoriesByVenueId(value.venueId)
					.pipe(
						map(response => {
							return managerActions.responseSuccessCategories({
								response: response
							});
						}),
						catchError(error => {
							return of(managerActions.responseErrorCategories({error}));
						})
					);
			})
		)
	);

	loadOptionGroups$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.loadOptionGroups),
			switchMap(value => {
				return this.managerService.getOptionGroupsByVenue(value.venueId).pipe(
					map(response => {
						return managerActions.responseSuccessOptionGroups({
							response: response
						});
					}),
					catchError(error => {
						return of(managerActions.responseErrorOptionGroups({error}));
					})
				);
			})
		)
	);

	loadTables$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.loadTables),
			switchMap(value => {
				return this.managerService.getTablesToVenue(value.venueId).pipe(
					map(response => {
						return managerActions.responseSuccessTables({
							response: response
						});
					}),
					catchError(error => {
						return of(managerActions.responseErrorTables({error}));
					})
				);
			})
		)
	);

	loadOrders$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.loadOrders),
			switchMap(value => {
				return this.managerService.getFilteredOrders(value).pipe(
					map(response => {
						return managerActions.responseSuccessOrders({
							response: response
						});
					}),
					catchError(error => {
						return of(managerActions.responseErrorTables({error}));
					})
				);
			})
		)
	);
	updateOrderStatus$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.updateOrderStatus),
			switchMap(value => {
				const orderCopy = JSON.parse(JSON.stringify(value.order));
				orderCopy.status = value.status;
				return this.managerService.updateOrderStatus(orderCopy).pipe(
					map(response => {
						return managerActions.updateOrderStatusSuccess({
							order: response
						});
					}),
					catchError(error => {
						return of(managerActions.updateOrderStatusFailed({error}));
					})
				);
			})
		)
	);
	updateArticlesOptionList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.updateArticlesOptionList),
			mergeMap(action => {
				return forkJoin([
					this.managerService.updateOptionArticleActive(action.data.active),
					timer(300).pipe(
						switchMap(() =>
							this.managerService.updateOptionArticleActive(
								action.data.disabled
							)
						)
					),
					this.managerService.updateOptionArticleActive(action.data.isActive),
					timer(500).pipe(
						switchMap(() =>
							this.managerService.updateOptionArticleActive(
								action.data.inActive
							)
						)
					)
				]).pipe(
					delay(1000),
					map(response =>
						managerActions.updateArticlesActiveSuccess({
							respone: 'Article/s was successfully updated :)'
						})
					),
					mergeMap(() => [
						managerActions.loadCategories({venueId: action.data.active.venue}),
						managerActions.loadOptionGroups({venueId: action.data.active.venue})
					]),
					catchError(error =>
						of(managerActions.updateArticlesActiveError({error}))
					)
				);
			})
		)
	);
	updateArticlesActive$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.updateArticlesActive),
			mergeMap(action => {
				console.log('updateArticlesActive Prev', action);
				if (action.data.isOption) {
					return this.managerService
						.updateOptionArticleActive(action.data)
						.pipe(
							map(response =>
								managerActions.updateArticlesActiveSuccess({respone: ''})
							),
							mergeMap(() => [
								managerActions.loadCategories({venueId: action.data.venue}),
								managerActions.loadOptionGroups({venueId: action.data.venue})
							]),
							catchError(error =>
								of(managerActions.updateArticlesActiveError({error}))
							)
						);
				} else {
					return this.managerService.updateArticleActive(action.data).pipe(
						map(response =>
							managerActions.updateArticlesActiveSuccess({respone: ''})
						),
						mergeMap(() => [
							managerActions.loadCategories({venueId: action.data.venue}),
							managerActions.loadOptionGroups({venueId: action.data.venue})
						]),
						catchError(error =>
							of(managerActions.updateArticlesActiveError({error}))
						)
					);
				}
			})
		)
	);

	updateOpeningHours$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.updateOpeningHoursAction),
			switchMap(value => {
				return this.managerService.updateOpeningHours(value.data).pipe(
					map(response => {
						this.toastrServ.success('Data was successfully updated...');
						this.navService.managerPage();

						return managerActions.updateArticlesActiveSuccess({
							respone: ''
						});
					}),
					catchError(error => {
						this.toastrServ.error(
							'Something went wrong while saving opening hours...'
						);
						return of(managerActions.updateOrderStatusFailed({error}));
					})
				);
			})
		)
	);
	syncJet$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.syncJet),
			switchMap(value => {
				return this.managerService.syncJet(value.data.venueId).pipe(
					map(response => {
						return managerActions.syncJetSuccess({response: {}});
					}),
					catchError(error => {
						return of(managerActions.syncJetError({error}));
					})
				);
			})
		)
	);
	syncWolt$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.syncWolt),
			switchMap(value => {
				return this.managerService.syncWolt(value.data.venueId).pipe(
					map(response => {
						return managerActions.syncWoltSuccess({response: {}});
					}),
					catchError(error => {
						return of(managerActions.syncWoltError({error}));
					})
				);
			})
		)
	);
	syncUber$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.syncUber),
			switchMap(value => {
				return this.managerService.syncUber(value.data.venueId).pipe(
					map(response => {
						return managerActions.syncUberSuccess({response: {}});
					}),
					catchError(error => {
						return of(managerActions.syncUberError({error}));
					})
				);
			})
		)
	);
	statusUber$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.statusUber),
			switchMap(value => {
				return this.managerService.statusUber(value.data.venueId).pipe(
					map((response: any) => {
						return managerActions.statusUberSuccess({
							isActive: response.isActive
						});
					}),
					catchError(error => {
						return of(managerActions.statusUberError({error}));
					})
				);
			})
		)
	);
	statusWolt$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.statusWolt),
			switchMap(value => {
				return this.managerService.statusWolt(value.data.venueId).pipe(
					map((response: any) => {
						return managerActions.statusWoltSuccess({
							isActive: response.isActive
						});
					}),
					catchError(error => {
						return of(managerActions.statusWoltError({error}));
					})
				);
			})
		)
	);
	loadSalesOverview$ = createEffect(() =>
		this.actions$.pipe(
			ofType(managerActions.loadSalesOverview),
			switchMap(params => {
				return this.managerService.loadSalesOverview(params).pipe(
					map((response: any) => {
						return managerActions.salesOverviewSuccess({
							response: response || []
						});
					}),
					catchError(error => {
						return of(managerActions.salesOverviewError({error}));
					})
				);
			})
		)
	);
	constructor(
		private actions$: Actions,
		private managerService: ManagerService
	) {}
}
